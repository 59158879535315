/* Component Dependencies */
var cardHeroTemplate = require('templates/cardHero.hbs');
var cardHeroDestinationTemplate = require('templates/cardHero-destination.hbs');
var cardHeroPropertyHeadlineTemplate = require('templates/cardHero-propertyHeadline.hbs');
var cardHeroOfferNextGenTemplate = require('templates/cardHero-offerNextGen.hbs');
var cardHeroOfferVersion2Template = require('templates/cardHero-enrollmentFormV2.hbs');
var AriesComponent = require('libs/aries-component');
var Constants = require('libs/constants');
require('libs/jquery.dotdotdot');
AriesComponent.create({
  type: 'cardHero',
  template: {
    'cardHero': cardHeroTemplate,
    'cardHero-destination' : cardHeroDestinationTemplate,
    'cardHero-propertyHeadline': cardHeroPropertyHeadlineTemplate,
    'cardHero-offerNextGen': cardHeroOfferNextGenTemplate,
    'cardHero-enrollmentFormV2': cardHeroOfferVersion2Template
  },
  bindEvents: function() {
    var _self = this;
    _self.addBgClass();
    _self.$el.find('.t-ito-text').dotdotdot({
      watch: 'window'
    });
    _self.positionHeading();
    _self.fontSizeHandler();
    _self.callOnResize();

    //Adding custom click tracking for join now and sign-in btn
    var customClickTrack = $('.tile-card-hero .custom_click_track');
    if(customClickTrack.length) {
      var joinNowBtn = $('.tile-card-hero a[href*="/loyalty/createAccount/createAccountPage1.mi"]'),
      signInBtn = $('.tile-card-hero a[href*="/sign-in.mi"]');
      joinNowBtn.attr("custom_click_track_value", "Marriott Bonvoy Hero|Join Now|internal|event167," + "evar48=MER-web-joinnow-hero" );
      signInBtn.attr("custom_click_track_value", "Marriott Bonvoy Hero|Sign in|internal|event167," + "evar48=MER-web-signin-hero" );
    }
  },
  options: {
    'MIN_DESKTOP_FONT_SIZE': 90,
    headingOffset : 32
  },
  addBgClass:function(){
    var $el = this.$el.find('.js-background-shape');
    var nobg = $el.data('nobg');
    var sum = _.sum($el.val());
     if(sum === 0 || nobg){
      this.$el.find('.m-text-overlay').addClass('t-no-bg').removeClass('t-bg-program');
    }else{
      this.$el.find('.m-text-overlay').addClass('t-bg-program');
    }
  },
  /**
    * This function is used to call other functions on resize of the window
    */
  callOnResize:function(){
		var _self = this;
		$(window).on('resize orientationchange', _self, function(event){
      var _self = event.data;
      _self.positionHeading();
      _self.fontSizeHandler();
		});
	},
  /**
    * This function is used to display heading on ito
    */ 
  positionHeading : function() {
    var overlayContainer = this.$el.find('.m-text-overlay');
    var overlayHeight = overlayContainer.innerHeight();
    overlayContainer.find("h1, h2").css("bottom", overlayHeight - this.options.headingOffset+"px");
  },
  // Handling for Russian
  fontSizeHandler: function(){
    var _self = this,
      localeKey = _self.context.context.localeKey,
      windowWidth = $(window).width();
    if((windowWidth < Constants.GLOBAL.TABLET_VIEWPORT) && (localeKey == 'ru_RU')){
      _self.$el.find('h2').removeClass('t-extend-h2-em');
      _self.$el.find('h1').removeClass('t-extend-h1-em');
    }
  }
});