var Constants = {};

Constants.GLOBAL = {
	TAKE_OVER_SPEED: 600,
	MOBILE_VIEWPORT: 600,
	TABLET_VIEWPORT: 768,
	DESKTOP_VIEWPORT: 1024,
	MOUSE_CLICK_EVENT: "click",
	MOUSE_OVER_EVENT: "mouseover",
	MOUSE_OUT_EVENT: "mouseout",
	CONST_ALL_KEY: "all",
	BLUR: 0,
	BACKSPACE: 8,
	TAB: 9,
	ENTER: 13,
	SHIFT: 16,
	CTRL: 17,
	ALT: 18,
	CAPS: 20,
	ESC: 27,
	SPACE: 32,
	PAGE_UP: 33,
	PAGE_DOWN: 34,
	END: 35,
	HOME: 36,
	ARROW_LEFT: 37,
	ARROW_UP: 38,
	ARROW_RIGHT: 39,
	ARROW_DOWN: 40,
	INSERT: 45,
	DELETE: 46,
	MILE_CONVERSION_MULTIPLY_FACTOR: 0.000621371192
};
Constants.AUTOCOMPLETE = {
	HIDE_ON_BLUR_DELAY: 250
}

Constants.AUTOCOMPLETE = {
	HIDE_ON_BLUR_DELAY: 250
};

Constants.RLM = {
  ACTIONS: {
    CART: {
      ADD_TO_CART:"addToCart",
      REMOVE_ROOM:"removeRoom"
    }
  }
};

Constants.SAVED_CARD_DETAILS = {
  ACTION_TYPE: {
    UPDATE: "update",
    CLEAR: "clear"
  }
};

Constants.SALES_FORCE_EVENT = {
  ACTION_TYPE: {
    PAUSE: "PAUSE",
    RESUME: "RESUME"
  }
};

Constants.CAPTION_DETAILS = {
  ACTION_TYPE: {
    SHORT: "short",
    MEDIUM: "medium"
  }
};

Constants.HOTEL_FACT_SHEET_ACCORDION_EVENT = {
  ACTION_TYPE: {
    EXPAND_ALL: "expandAll",
    COLLAPSE_ALL: "collapseAll",
    EXPAND: "expand",
    COLLAPSE: "collapse"
  }
};

Constants.FILTER = {
  ADD: 'add',
  REMOVE: 'remove',
  CLEAR_ALL: 'clearAll',
  RESET: 'reset',
  APPLY: 'apply',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MODIFIED: 'modified',
  CURRENT: 'current',
  ERROR_REDIRECT_URI: 'error-redirect-uri',
  TAKE_OVER_SPEED: 500
};

Constants.SEARCH_FORM = {
	TAKE_OVER_SPEED: 500,
	VARIATIONS: {
		ADVANCED_SEARCH: 'ADVANCED_SEARCH',
		HOMEPAGE: 'HOMEPAGE',
		EDIT_SEARCH: 'EDIT_SEARCH',
		RATE_LIST_EDIT: 'RATE_LIST_EDIT',
		MODIFY_RES: 'MODIFY_RES',
		HEADER: 'HEADER',
		AVAILABILITY_SEARCH: 'AVAILABILITY_SEARCH',
		DEALS_AVAILABILITY_SEARCH: 'DEALS_AVAILABILITY_SEARCH',
    CHECK_AVAILABILITY_HWS: 'CHECK_AVAILABILITY_HWS',
    ASSOCIATE_SEARCH: 'ASSOCIATE_SEARCH',
	}
};

Constants.PROPERTY_RECORD = {
  TAKE_OVER_SPEED: 500,
  NAVIGATION_SPEED: 500,
  SWIPE_THRESHOLD_DISTANCE: 100,  //required min distance traveled to be considered swipe
  MOBILE_WIDTH: 480,
  MARKER_PIN_BLUE: 'blue',
  MARKER_PIN_BLACK: 'black',
  MAP_INFO_WINDOW_IDLE_TIME: 5000,
  HOTEL_QUICK_VIEW_ANIMATION_SPEED: 300
};

Constants.KEY_CODE = {
  ENTER_KEY: 13,
  ESC_KEY: 27,
  UP_KEY: 38,
  DOWN_KEY: 40
};

Constants.SCREEN_WIDTH = {
  DATE_SHORT_FORMAT_START_WIDTH: 0,
  DATE_SHORT_FORMAT_END_WIDTH: 0,
  DATE_SHORT_FORMAT_END_WIDTH_HWS: 1140
};

Constants.MEDIA_QUERY = {
	EXTRA_LARGE: '(min-width: 64.0625rem)'
};

Constants.SRP_VIEW = {
  LIST: 'list'
}

Constants.LOCALE = {
  JAPANESE: 'ja_JP',
  KOREAN: 'ko_KR',
  RUSSIAN: 'ru_RU'
}

Constants.LOCALE_HYPHEN = {
  JAPANESE: 'ja-JP',
  KOREAN: 'ko-KR',
  RUSSIAN: 'ru-RU'
};

Constants.LANGUAGE_PARITY_LOCALE = {
  LOCALE : ['ko-KR','es-ES','ja-JP','de-DE','fr-FR','ru-RU']
};

module.exports = Constants;
